import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "@/stores/auth";

const routes = [
    {
        path: '/',
        name: 'landingPage',
        meta: {
            hideForAuth: true
        },
        component: () => import(/* webpackChunkName: "LandingPageView, webpackPrefetch: true" */ '../views/LandingPageView.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            hideForAuth: true
        },
        component: () => import(/* webpackChunkName: "LoginView, webpackPrefetch: true" */ '../views/LoginView.vue')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
            hideForAuth: true
        },
        component: () => import(/* webpackChunkName: "ResetPasswordView, webpackPrefetch: true" */ '../views/ResetPasswordView.vue')
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            hideForAuth: true
        },
        component: () => import(/* webpackChunkName: "RegisterView, webpackPrefetch: true" */ '../views/RegisterView.vue')
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "HomeView, webpackPrefetch: true" */ '../views/HomeView.vue')
    },
    {
        path: '/news/:id',
        name: 'detailNews',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "DetailNewsView", webpackPrefetch: true*/ '../views/DetailNewsView.vue')
    },
    {
        path: '/map',
        name: 'map',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "MapView, webpackPrefetch: true" */ '../views/MapView.vue')
    },
    {
        path: '/scanner',
        name: 'scanner',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "ScannerView, webpackPrefetch: true" */ '../views/ScannerView.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {
            requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "ProfileView, webpackPrefetch: true" */ '../views/ProfileView.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: "/",
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (authStore.isAuthenticated) {
            next();
            return;
        }
        next("/");
    } else {
        next();
    }
});


router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.hideForAuth)) {
        if (useAuthStore().isAuthenticated) {
            next("/home");
            return;
        }
        next();
    } else {
        next();
    }
});


export default router
